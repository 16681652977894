/**
 * TODO: create a generic component of Heading and repass the data as a prop
 * example:
 * <!-- usability --!>
 * <Layout headingProps={props} />
 *
 * <!-- implementation (inside of this component) --!>
 * <div>
 *  <Heading {...props} />
 * </div>
 */
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { cloneElement, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import Footer from 'components/Footer';
import LazyHydration from 'components/LazyHydration';

import { omit } from 'lib/object';

import LayoutMargin from './Margin';

const ScrollToTopButton = dynamic(
  () => import('components/ScrollToTopButton'),
  { ssr: false }
);

const Layout = ({
  children,
  footerProps = {},
  gaEventCategory = null,
  gridProps,
  headingComponent = null,
  isWebView = false,
  maxWidthOnMobileDevices = false,
  flatBg = false,
  scrollToTopButton = false,
  sidebarComponent = null,
  withGridLayout = true,
  ...rest
}) => {
  useEffect(() => {
    if (flatBg) {
      document.body.classList.add('v2');
      return;
    }

    document.body.classList.remove('v2');
  }, [flatBg]);

  return (
    <>
      <LayoutMargin
        flatBg={flatBg}
        maxWidthOnMobileDevices={maxWidthOnMobileDevices}
        {...rest}
      >
        {/*
          TODO: to remove `cloneElement` is necessary to standardize the element
          wrapper of heading components
        */}
        {headingComponent &&
          cloneElement(
            headingComponent,
            maxWidthOnMobileDevices ? { className: 'mx-4 lg:mx-0' } : {}
          )}
        {withGridLayout ? (
          <main
            className={twMerge(
              'grid w-full grid-flow-row grid-cols-4 gap-x-4 md:grid-cols-12',
              gridProps?.className
            )}
            {...omit(gridProps, ['className'])}
          >
            <div className="col-span-4 inline-flex w-full flex-col flex-nowrap justify-self-stretch md:col-span-12 xl:col-span-9">
              {children}
            </div>
            {sidebarComponent}
          </main>
        ) : (
          <>{children}</>
        )}
        {scrollToTopButton && (
          <ScrollToTopButton
            className="mb-16"
            gaEventCategory={gaEventCategory}
          />
        )}
      </LayoutMargin>
      {!isWebView && (
        <LazyHydration
          force={footerProps.force}
          placeholderSize={{ height: 1752 }}
        >
          <Footer {...footerProps} />
        </LazyHydration>
      )}
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  flatBg: PropTypes.bool,
  footerProps: PropTypes.shape(),
  gaEventCategory: PropTypes.string,
  gridProps: PropTypes.shape(),
  headingComponent: PropTypes.shape(),
  isWebView: PropTypes.bool,
  maxWidthOnMobileDevices: PropTypes.bool,
  scrollToTopButton: PropTypes.bool,
  sidebarComponent: PropTypes.node,
  withGridLayout: PropTypes.bool,
};

export default Layout;
